<template>
  <b-row>
    <b-col v-if="withExpenseCode">
      <label>Código despesa</label>
      <multiselect
        :value="cds.find(cd => cd.value === filters.expense_code)"
        @select="e => setValue('expense_code', e.value)"
        placeholder="Selecionar"
        :options="cds"
        track-by="value"
        label="label"
        :allow-empty="false"
        :showLabels="false"
        :showNoResults="false"
        openDirection="bottom"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.label }}
        </template>
        <template slot="noOptions">
          Nenhuma opção
        </template>
        <template slot="noResult">
          Nenhum resultado
        </template>
      </multiselect>
    </b-col>
    <b-col v-if="withTable">
      <label for="table" class="title-styles">Tabela</label>
        <multiselect
          id="table"
          track-by="value"
          label="label"
          :value="tableOptions.find(table => table.value === filters.table_reference)"
          @select="e => setValue('table_reference', e.value)"
          placeholder="Selecionar"
          :options="tableOptions"
          :allow-empty="false"
          :showLabels="false"
          :showNoResults="false"
          class="with-border"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <Ellipsis>{{ option.label }}</Ellipsis>
          </template>
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions">
            Nenhuma opção
          </template>
          <template slot="noResult"> Nenhum resultado </template>
      </multiselect>
    </b-col>
    <b-col cols="4">
      <label>Item</label>
      <div class="item-search">
        <Search class="search"/>
        <b-input 
          v-model="filters.item"
          debounce="1000"
          :placeholder="
            type === 'brasindice' 
            ? 'Buscar código TISS, código TUSS ou nome'
            : 'Buscar código Simpro, código TUSS ou descrição'
          "
        />
      </div>
    </b-col>
    <b-col>
      <label>Fabricante</label>
      <b-input 
        v-model="filters.manufacturer"
        debounce="1000"
        placeholder="Buscar fabricante"
      />
    </b-col>
    <b-col>
      <label>Status</label>
      <multiselect
        :value="statusOptions.find(status => status.value === filters.status)"
        @select="e => setValue('status', e.value)"
        placeholder="Selecionar"
        track-by="value"
        label="label"
        :options="statusOptions"
        :allow-empty="false"
        :showLabels="false"
        :showNoResults="false"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noOptions">
          Nenhuma opção
        </template>
        <template slot="noResult">
          Nenhum resultado
        </template>
      </multiselect>
    </b-col>
    <b-col v-if="withFraction">
      <label for="fraction" class="title-styles">Fração</label>
      <multiselect
        :value="unitMeasures.find(option => option.value === filters.fraction)"
        @select="e => setValue('fraction', e.value)"
        placeholder="Selecionar"
        track-by="value"
        label="label"
        :options="unitMeasures"
        :allow-empty="false"
        :showLabels="false"
        :showNoResults="false"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noOptions">
          Nenhuma opção
        </template>
        <template slot="noResult">
          Nenhum resultado
        </template>
      </multiselect>
    </b-col>
  </b-row>
</template>

<script>
import cdsOptions from '@/components/Tiss/Data/cds'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'

export default {
  name: 'tableFilters',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
  },
  props: {
    type: String,
    filters: {
      type: Object,
      default: () => ({
        item: null,
        expense_code: null,
        table_reference: null,
        status: null,
        manufacturer: null,
        fraction: null,
      }),
    },
    withExpenseCode: Boolean,
    withTable: Boolean,
    withFraction: Boolean,
  },
  data() {
    return {
      cds: [{ "label": "Todos", "value": null }].concat(cdsOptions),
      unitMeasures: [{ "label": "Todos", "value": null }].concat(unitMeasures),
      tableOptions: [
        { label: 'Todas as tabelas TUSS', value: null },
        { label: '18 - Taxas, diárias e gases medicinais', value: 18 },
        { label: '19 - Materiais e OPME', value: 19 },
        { label: '20 - Medicamentos', value: 20 },
      ],
      statusOptions: [
        { label: 'Todos', value: null},
        { label: 'Ativo', value: true },
        { label: 'Inativo', value: false },
      ],
    }
  },
  methods: {
    setValue(key, value) {
      this.$set(this.filters, key, value)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  color: var(--dark-blue, #0C1D59);
  font-size: 16px;
  font-family: Nunito Sans;
  font-weight: 600;
  line-height: 150%;
}

.item-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  .search {
    z-index: 3;
    margin: 0 0 2px 10px;
    width: 24px;
    height: 24px;
    position: absolute;
    stroke: var(--neutral-500, #A4B1DF);
  }

  input {
    position: relative;
    padding-left: 40px;
  }
}
</style>